
<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter flex items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/logo/logo-204x204.png" alt="login" class="responsive my-auto p-10">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="px-8 pt-8 register-tabs-container">
                <div class="vx-card__title mb-4">
                    <h3 class="mb-4">Buat Akun</h3>
                    <p>Isi formulir di bawah ini untuk membuat akun baru.</p>
                </div>
                <div class="clearfix mb-5">
                  <vs-input
                    v-validate="'required|min:3'"
                    data-vv-validate-on="blur"
                    label-placeholder="Nama"
                    name="Nama"
                    placeholder="Nama"
                    v-model="dataNama"
                    class="w-full" />
                  <span class="text-danger text-sm">{{ errors.first('Nama') }}</span>

                  <vs-input
                    v-validate="'required|numeric'"
                    data-vv-validate-on="blur"
                    label-placeholder="No. Telepon"
                    name="Telepon"
                    placeholder="No. Telepon"
                    v-model="dataTelepon"
                    class="w-full mt-6" />
                  <span class="text-danger text-sm">{{ errors.first('Telepon') }}</span>

                  <vs-input
                    v-validate="'required|email'"
                    data-vv-validate-on="blur"
                    name="Email"
                    type="email"
                    label-placeholder="Email"
                    placeholder="Email"
                    v-model="dataEmail"
                    class="w-full mt-6" />
                  <span class="text-danger text-sm">{{ errors.first('Email') }}</span>

                  <vs-divider></vs-divider>

                  <vs-input
                    v-validate="'required|alpha_dash|min:3'"
                    data-vv-validate-on="blur"
                    label-placeholder="Username"
                    name="Username"
                    placeholder="Username"
                    v-model="dataUsername"
                    class="w-full mt-6" />
                  <span class="text-danger text-sm">{{ errors.first('Username') }}</span>

                  <vs-input
                    ref="password"
                    type="password"
                    data-vv-validate-on="blur"
                    v-validate="'required|min:5'"
                    name="password"
                    label-placeholder="Password"
                    placeholder="Password"
                    v-model="dataPassword"
                    class="w-full mt-6" />
                  <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                  <vs-input
                    type="password"
                    v-validate="'min:5|confirmed:password'"
                    data-vv-validate-on="blur"
                    data-vv-as="password"
                    name="confirm_password"
                    label-placeholder="Konfirmasi Password"
                    placeholder="Konfirmasi Password"
                    v-model="confirm_password"
                    class="w-full mt-6" />
                  <span class="text-danger text-sm">{{ errors.first('confirm_password') }}</span>

                  <vs-checkbox v-model="isTermsConditionAccepted" class="mt-6">Saya menerima syarat & ketentuan.</vs-checkbox>
                  <vs-button  type="border" to="/login" class="mt-6">Login</vs-button>
                  <vs-button class="float-right mt-6" @click="registerUser" :disabled="!validateForm">Register</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        dataNama: '',
        dataTelepon: '',
        dataEmail: '',
        dataUsername: '',
        dataPassword: '',
        confirm_password: '',
        isTermsConditionAccepted: true
      }
    },
    computed: {
      validateForm () {
        return !this.errors.any() && this.dataNama && this.dataTelepon !== '' && this.dataEmail !== '' && this.dataUsername !== '' && this.dataPassword !== '' && this.confirm_password !== '' && this.isTermsConditionAccepted === true
      }
    },
    methods: {
      registerUser () {
        if (!this.validateForm) return
        const payload = {
          nama: this.dataNama,
          telepon: this.dataTelepon,
          email: this.dataEmail,
          username: this.dataUsername,
          password: this.dataPassword
        }
        this.axios.post('/register', payload)
        .then((response) => {
          this.$vs.loading.close()
          this.$vs.notify({
            time  : 8000,
            title : 'Login Berhasil',
            color : 'success',
            text  : response.data.message
          })
          this.$router.push('/login')
          this.close()
        })
        .catch((error) => {
          this.$vs.loading.close()
          let { data } = error.response
          this.$vs.notify({
            title: 'Error',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
      }
    }
  }
  </script>

<style lang="scss">
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
</style>
